import React from 'react';
import SquareImage from '../images/squareImage';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Tutor = styled.div`
  padding: 0 13.5px;

  position: relative;
  flex: 0 0 20%;
  max-width: 20%;

  margin: 0 0 54px 0;

  & > a {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 767px) {
    padding: 0 10px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin: 0 0 27px 0;
  }
`;

const TutorName = styled.h2`
  font-size: 20px;
  color: #000;

  padding: 27px 0 0 0;

  @media (max-width: 767px) {
    font-size: 15px;
    padding: 15px 0 0 0;
  }
`;

const TutorBio = props => (
  <Tutor>
    <Link to={'/about/tutor/' + props.link}>
      <SquareImage image={props.details.image.url} />
      <TutorName>{props.details.name.text}</TutorName>
    </Link>
  </Tutor>
);

export default TutorBio;
