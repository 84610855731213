import React from 'react';
import styled from 'styled-components';

const LandscapeImageContainer = styled.div`
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 66.666%;
  }
`;

const Landscape = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const LandscapeImage = props => (
  <LandscapeImageContainer>
    <Landscape src={props.image} />
  </LandscapeImageContainer>
);
export default LandscapeImage;
