import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'gatsby';

const TextContainer = styled.div`
  font-size: 20px;

  padding: ${props => props.padding || '0 0 108px 0'};

  & a {
    text-decoration: none;
    color: ${props => props.linkColor || 'inherit'};
  }

  & p:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 20px 0;
  }

  @media (max-width: 576px) {
    font-size: 15px;
    line-height: 19px;
  }
`;

const Text = styled.div``;

const TextboxWithHeading = props => (
  <Col col={12}>
    <Row>
      {!props.text && (
        <Col col={12}>
          <TextContainer padding={props.padding} linkColor={props.linkColor}>
            {props.heading}
          </TextContainer>
        </Col>
      )}

      {props.text && (
        <React.Fragment>
          <Col col={12} sm={3} lg={3}>
            <TextContainer padding={props.padding} linkColor={props.linkColor}>
              {props.heading}
            </TextContainer>
          </Col>
          <Col col={12} sm={9} lg={6}>
            <TextContainer padding={props.padding} linkColor={props.linkColor}>
              <Text
                dangerouslySetInnerHTML={{
                  __html: props.text,
                }}
              />

              {props.link && (
                <React.Fragment>
                  <br />
                  <Link to={props.link}>Read More →</Link>
                </React.Fragment>
              )}
            </TextContainer>
          </Col>
        </React.Fragment>
      )}
    </Row>
  </Col>
);

export default TextboxWithHeading;
