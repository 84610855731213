import React from 'react';
import LandscapeImage from '../images/landscapeImage';
import { Link } from 'gatsby';
import styled from 'styled-components';
import TimeArrow from '../text/timeArrow';

const Location = styled.div`
  margin: 0 0 54px 0;
  & > a {
    color: inherit;
    text-decoration: none;
  }

  & img {
    filter: grayscale(100);
    mix-blend-mode: multiply;
  }

  &:hover img {
    filter: none;
    mix-blend-mode: unset;
  }

  @media (max-width: 767px) {
    margin: 0 0 28px 0;
  }
`;

const LocationName = styled.h2`
  font-size: 20px;
  color: #000;

  padding: 27px 0 0 0;

  @media (max-width: 767px) {
    font-size: 15px;

    padding: 14px 0 0 0;
  }
`;

const CityCountry = styled.span`
  text-transform: uppercase;
`;

const LocationCard = props => (
  <Location>
    <Link to={props.link}>
      <LandscapeImage image={props.details.image.url} />
      <LocationName>
        <CityCountry>{props.details.subtitle.text}</CityCountry>{' '}
        <TimeArrow>→</TimeArrow> {props.details.title.text}
      </LocationName>
    </Link>
  </Location>
);

export default LocationCard;
