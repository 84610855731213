import React from 'react';
import styled from 'styled-components';

const ContentContainerStyle = styled.div`
  padding: ${props => props.padding || '108px 27px'};
  width: 100%;

  position: relative;

  @media (max-width: 767px) {
    padding: ${props => props.mobilePadding || '40px 9px'};
  }
`;

const ContentContainer = ({ children, padding, mobilePadding, id }) => (
  <ContentContainerStyle
    mobilePadding={mobilePadding}
    padding={padding}
    id={id}
  >
    {children}
  </ContentContainerStyle>
);

export default ContentContainer;
