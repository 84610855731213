import React from 'react';
import styled from 'styled-components';

const ArrowWrapper = styled.span`
  display: inline;
  top: -2px;
  position: relative;
`;

const TimeArrow = ({ children }) => <ArrowWrapper>{children}</ArrowWrapper>;

export default TimeArrow;
