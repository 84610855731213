import React from 'react';
import styled from 'styled-components';

const SquareImageContainer = styled.div`
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }
`;

const Square = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const squareImage = props => (
  <SquareImageContainer>
    <Square src={props.image} />
  </SquareImageContainer>
);
export default squareImage;
